<template>
	<li :class="group.id === +$route.query.group ? 'active' : ''">
		<router-link :to="linkAddress">
			<div class="link">
				<div class="img-container">
					<div class="img-wrapper">
						<img
							class="image"
							:src="
								group.image
									? `https://cdn.jfservice.ru/alerts/icons/${group.image}`
									: '/img/alerts/uk.png'
							"
							alt="link_icon"
						/>
					</div>
				</div>
				<div class="link-content">
					<div class="title-wrapper">
						<div class="link-title">{{ group.name }}</div>
						<div
							v-if="group.lastMessage && !group.lastMessage.readed"
							class="new-msg-mark"
						></div>
					</div>
					<div class="date">
						{{
							group.lastMessage &&
								new Date(group.lastMessage.createdDate).toLocaleString()
						}}
					</div>

					<div class="link-subtitle">
						<div v-if="group.lastMessage && group.lastMessage.content" class="link-subtitle__text">
							{{ group.lastMessage.content }}
						</div>
					</div>
				</div>
			</div>
		</router-link>
	</li>
</template>
<script>
export default {
	props: ["group"],
	computed: {
		linkAddress() {
			return {
				name: "alerts",
				query: {
					group: this.group.id
				}
			};
		}
	}
};
</script>
<style scoped>
li .link,
li .img-wrapper {
	transition: background-color 0.3s ease-in-out;
}

li:not(:last-child) {
	border-bottom: 1px solid var(--a-link-border);
}
.link {
	background: var(--a-link-bg);
	padding: 1em;
	display: flex;
	gap: 1.5em;
	align-items: center;
}

.img-container {
	flex-shrink: 0;
}
.img-wrapper {
	width: 54px;
	height: 54px;
	background: var(--a-link-img-bg);
	border-radius: 0.5em;
}
.image {
	margin: -2px 0 0 2px;
	height: 100%;
	object-fit: contain;
}
.link-content {
	align-self: flex-start;
	flex-grow: 1;
	overflow: hidden;
}
.title-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.link-title {
	font-weight: 600;
	font-size: 19px;
	line-height: 23px;
	color: var(--a-link-title-color);
}
.new-msg-mark {
	height: 0.75em;
	width: 0.75em;
	background: var(--a-link-new-msg-mark);
	border-radius: 100%;
}
.date {
	margin-top: 0.25em;
	color: var(--a-link-date-color);
	font-weight: 400;
	font-size: 13px;
}
.link-subtitle {
	margin-top: 0.5em;
	font-weight: 400;
	font-size: 16px;
	color: var(--a-link-subtitle-color);
	width: 100%;
}

.link-subtitle__text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.active {
	--a-link-bg: var(--a-active-link-bg);
	--a-link-img-bg: var(--a-active-link-img-bg);
}
</style>
